import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import UserAvatar from "../assets/images/user_avatar.png";
import { useHistory } from "react-router-dom";
import { sidebar_items } from "../utils/Constants";

const CommonSideBar = () => {
    const history = useHistory();
    const location = useLocation();
    const AuthenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [navRoute, setNavRoute] = useState(window.location.pathname);

    useEffect(() => {
        setNavRoute(location.pathname);
    }, [location.pathname]);

    useEffect(() => {
        sidebar_items.forEach((sideMenuItem, sideMenuIndex) => {
            sideMenuItem.submenuItems?.forEach((submenuItem, submenuIndex) => {
                if (navRoute.includes(submenuItem?.submenuRoute)) {
                    document.getElementById(submenuItem.submenuNavItemId).classList.add("active");
                    document.getElementById(sideMenuItem.aTagMenuId).classList.add("nav-link");
                    document.getElementById(sideMenuItem.menuNavItemId).classList.add("active");
                    document.getElementById(sideMenuItem.menuId).classList.add("show");
                } else {
                    document.getElementById(submenuItem.submenuNavItemId).classList.remove("active");
                    document.getElementById(submenuItem.submenuNavItemId).classList.remove("active");
                }
            });
        });
    }, [navRoute]);

    const clickMenuItem = (menuDetails) => {
        document.getElementById(menuDetails.menuNavItemId).classList.add("active");
        sidebar_items.forEach((menuItem, menuIndex) => {
            if (menuItem.menuNavItemId !== menuDetails.menuNavItemId) {
                document.getElementById(menuItem.menuNavItemId).classList.remove("active");
                document.getElementById(menuItem.menuId)?.classList.remove("show");
                menuItem.submenuItems.forEach(submenuItem => {
                    document.getElementById(submenuItem.submenuNavItemId).classList.remove("active");
                });
            } else {
                document.getElementById(menuItem.menuNavItemId).classList.add("active");
            }
        });
        // console.log(menuDetails.submenuItems.length);
        if (menuDetails?.submenuItems?.length === 0) {
            document.getElementById('sidebar')?.classList.remove('active');
        }
    }

    const clickSubmenuItem = (submenuDetails) => {
        setNavRoute(submenuDetails.submenuRoute);
        sidebar_items.forEach((menuItem, menuIndex) => {
            menuItem.submenuItems.forEach((submenuItem, submenuIndex) => {
                if (submenuItem.submenuNavItemId === submenuDetails.submenuNavItemId) {
                    document.getElementById(submenuItem.submenuNavItemId).classList.add("active");
                } else {
                    document.getElementById(submenuItem.submenuNavItemId).classList.remove("active");
                }
            })
        });
        document.getElementById('sidebar')?.classList.remove('active');
    }

    return (
        <nav className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav">
                <li className="nav-item nav-profile">
                    <a href="#" className="nav-link">
                        <div className="nav-profile-image">
                            <img src={UserAvatar} alt="profile" />
                            <span className="login-status online"></span>
                        </div>
                        <div className="nav-profile-text d-flex flex-column">
                            <span className="font-weight-bold mb-2">{AuthenticationReducer?.logUserAllDetails?.user?.firstName} {AuthenticationReducer?.logUserAllDetails?.user?.lastName}</span>
                            <span className="text-secondary text-small">{AuthenticationReducer?.logUserAllDetails?.user?.userRoleDetails?.displayName}</span>
                        </div>
                        <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
                    </a>
                </li>
                {sidebar_items.map((menuItem, menuIndex) =>
                    <li
                        className="nav-item"
                        key={menuIndex}
                        id={`${menuItem.menuNavItemId}`}
                        onClick={() => {
                            clickMenuItem(menuItem);
                            if (!!menuItem?.menuRoute) {
                                history.push(menuItem.menuRoute)
                            }
                        }}
                    >
                        <a className="nav-link" id={menuItem.aTagMenuId} data-bs-toggle="collapse" href={`#${menuItem.menuId}`} aria-expanded="false" aria-controls={`ui-basic${menuItem.menuId}`}>
                            <span className="menu-title">{menuItem.menuName}</span>
                            {menuItem.submenuItems.length > 0 && (
                                <i className="menu-arrow"></i>
                            )}
                            <i className={menuItem.menuIcon}></i>
                        </a>
                        {menuItem.submenuItems.length > 0 && (
                            <div className="collapse" id={`${menuItem.menuId}`}>
                                <ul className="nav flex-column sub-menu">
                                    {menuItem.submenuItems.map((submenuItem, submenuIndex) =>
                                        <li key={submenuIndex} className="nav-item" onClick={() => clickSubmenuItem(submenuItem)}> <a className="nav-link" id={`${submenuItem.submenuNavItemId}`} style={{ cursor: 'pointer' }} onClick={() => history.push(submenuItem?.submenuRoute)}>{submenuItem.submenuName}</a></li>
                                    )}
                                </ul>
                            </div>
                        )}
                    </li>
                )}
            </ul>
        </nav>
    )
}

export default CommonSideBar;