import {
    ERROR_TO_GET_HOUSEHOLD_VILLAGE_WISE,
    FAILED_ADD_FAMILY_MEMBERS,
    FAILED_FAMILY_MEMBERS,
    FAILED_TO_UPDATE_MEMBER_PHOTO,
    REQUEST_ADD_FAMILY_MEMBERS,
    REQUEST_FAMILY_MEMBERS,
    REQUEST_TO_CHANGE_MEMEBER_REDUCER_DATA,
    REQUEST_TO_GET_HOUSEHOLD_VILLAGE_WISE,
    REQUEST_TO_UPDATE_MEMBER_PHOTO,
    RESET_REDUCER_STATE,
    SUCCESS_ADD_FAMILY_MEMBERS,
    SUCCESS_FAMILY_MEMBERS,
    SUCCESS_TO_GET_HOUSEHOLD_VILLAGE_WISE,
    SUCCESS_TO_UPDATE_MEMBER_PHOTO
} from "../../utils/Constants";

const initialState = {
    status: "",
    isActivityLoader: false,
    memberDataResponse: {
        members: []
    },
    memberData: null,
    newMemberAddEditResponse: null,
    apiError: null,
    requestHousehols: false,
    houseHoldDataVillageWise: null
}

const FamilyMemberReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_FAMILY_MEMBERS:
            return {
                ...state,
                isActivityLoader: true,
                status: action.type
            }
        case SUCCESS_FAMILY_MEMBERS:
            return {
                ...state,
                isActivityLoader: false,
                status: action.type,
                memberDataResponse: action.response,
                memberData: action.response?.memberDetails
            }
        case FAILED_FAMILY_MEMBERS:
            return {
                ...state,
                isActivityLoader: false,
                status: action.type,
                apiError: action.error
            }
        case REQUEST_ADD_FAMILY_MEMBERS:
            return {
                ...state,
                isActivityLoader: true,
                status: action.type
            }
        case SUCCESS_ADD_FAMILY_MEMBERS:
            return {
                ...state,
                isActivityLoader: false,
                status: action.type,
                newMemberAddEditResponse: action.response
            }
        case FAILED_ADD_FAMILY_MEMBERS:
            return {
                ...state,
                isActivityLoader: false,
                status: action.type,
                apiError: action.error
            }
        case REQUEST_TO_UPDATE_MEMBER_PHOTO:
            return {
                ...state,
                isActivityLoader: true,
                status: action.type,
            }
        case SUCCESS_TO_UPDATE_MEMBER_PHOTO:
            return {
                ...state,
                isActivityLoader: false,
                status: action.type,
                newMemberAddEditResponse: action.response
            }
        case FAILED_TO_UPDATE_MEMBER_PHOTO:
            return {
                ...state,
                isActivityLoader: false,
                status: action.type,
                apiError: action.error
            }
        case REQUEST_TO_CHANGE_MEMEBER_REDUCER_DATA:
            return {
                ...state,
                isActivityLoader: false,
                status: action.type,
                memberData: action.payload
            }
        case REQUEST_TO_GET_HOUSEHOLD_VILLAGE_WISE:
            return {
                ...state,
                status: action.type,
                isActivityLoader: true
            }
        case SUCCESS_TO_GET_HOUSEHOLD_VILLAGE_WISE:
            return {
                ...state,
                status: action.type,
                isActivityLoader: false,
                houseHoldDataVillageWise: action?.response
            }
        case ERROR_TO_GET_HOUSEHOLD_VILLAGE_WISE:
            return {
                ...state,
                status: action.type,
                isActivityLoader: false,
                apiError: action?.error
            }
        case RESET_REDUCER_STATE:
            return {
                ...state,
                ...action?.payload
            }
        default:
            return state;
    }
}

export default FamilyMemberReducer;