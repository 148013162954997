export const JWT_AUTH_TOKEN = "__JWT_AUTH_TOKEN__";
export const USER_ID = `__USER_ID__`;
export const SUCCESS = "SUCCESS";
export const WARNING = "WARNING";
export const ERROR = "ERROR";
export const APP = "APP";
export const WEB = "WEB";

// http request method
export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";
export const DELETE = "DELETE";

// api sub routes
export const LOGIN_ROUTE = "user/login";
export const LOGIN_USER_DETAILS_ROUTE = "user/details";
export const MASTER_ROUTE = "master/";
export const MEMBERS_DATA_ROUTE = "family/members";
export const MEMBERS_ADD_UPDATE = "family/member/add";
export const MEMBERS_MODIFICATION = "family/member/update";
export const MEMBER_PHOTO_UPDATE = "family/member/addphoto";
export const DASHBOARD_DATA = "user/dashboard";
export const REGISTER_NEW_LOGIN_USER = `user/register`;
export const GET_ALL_LOGIN_USERS = `user/all`;
export const RESET_PASSWORD_LOGIN_USERS = `user/resetpassword`;
export const ADD_MEDICINE_CATEGORY_ROUTE = `medicine/category/add`;
export const UPDATE_MEDICINE_CATEGORY_ROUTE = `medicine/category/update`;
export const MEDICINE_CATEGORY_LIST_ROUTE = `medicine/categories`;
export const MEDICINE_ADD_ROUTE = `medicine/add`;
export const MEDICINE_UPDATE_ROUTE = `medicine/update`;
export const MEDICINE_LIST_ROUTE = `medicines`;
export const MEDICINE_STOCK_MANAGEMENT = `stock/manage`;
export const MEDICINE_STOCK_HISTORY = `stock/history`;
export const PRESCRIPTION_DETAILS_ROUTE = `prescriptions`;
export const HOUSEHOLD_ROUTE = `household`;
export const HOUSEHOLD_SURVEY_DATA_ADD = `add-household-survey`;
export const GET_HOUSEHOLD_SURVEY_DATA = `household-survey-data`;
export const VILLAGE_SURVEY_DATA_ADD = `add-village-survey`;
export const GET_VILLAGE_SURVEY_DATA = `village-survey-data`;

// validation message
export const EMPTY_EMAIL_ALERT = "Please enter your email";
export const INVALID_EMAIL_ALERT = "Please enter a valid email";
export const EMPTY_PASSWORD_ALERT = "Please enter your password";

export const ERROR_API_404_ALERT = "Server route not found";
export const ERROR_API_500_ALERT = "Bad response from server";

// action type constants
export const REQUEST_USER_LOGIN = "REQUEST_USER_LOGIN";
export const SUCCESS_USER_LOGIN = "SUCCESS_USER_LOGIN";
export const FAILED_USER_LOGIN = "FAILED_USER_LOGIN";

export const REQUEST_LOGIN_USER_DETAILS = "REQUEST_LOGIN_USER_DETAILS";
export const SUCCESS_LOGIN_USER_DETAILS = "SUCCESS_LOGIN_USER_DETAILS";
export const FAILED_LOGIN_USER_DETAILS = "FAILED_LOGIN_USER_DETAILS";

export const REQUEST_MASTER_LIST = "REQUEST_MASTER_LIST";
export const SUCCESS_MASTER_LIST = "SUCCESS_MASTER_LIST";
export const FAILED_MASTER_LIST = "FAILED_MASTER_LIST";

export const REQUEST_FAMILY_MEMBERS = "REQUEST_FAMILY_MEMBERS";
export const SUCCESS_FAMILY_MEMBERS = "SUCCESS_FAMILY_MEMBERS";
export const FAILED_FAMILY_MEMBERS = "FAILED_FAMILY_MEMBERS";

export const REQUEST_ADD_FAMILY_MEMBERS = "REQUEST_ADD_FAMILY_MEMBERS";
export const SUCCESS_ADD_FAMILY_MEMBERS = "SUCCESS_ADD_FAMILY_MEMBERS";
export const FAILED_ADD_FAMILY_MEMBERS = "FAILED_ADD_FAMILY_MEMBERS";

export const REQUEST_GET_DASHBOARD_DATA = "REQUEST_GET_DASHBOARD_DATA";
export const SUCCESS_GET_DASHBOARD_DATA = "SUCCESS_GET_DASHBOARD_DATA";
export const FAILED_GET_DASHBOARD_DATA = "FAILED_GET_DASHBOARD_DATA";

export const REQUEST_TO_UPDATE_MEMBER_PHOTO = "REQUEST_TO_UPDATE_MEMBER_PHOTO";
export const SUCCESS_TO_UPDATE_MEMBER_PHOTO = "SUCCESS_TO_UPDATE_MEMBER_PHOTO";
export const FAILED_TO_UPDATE_MEMBER_PHOTO = "FAILED_TO_UPDATE_MEMBER_PHOTO";

export const REQUEST_TO_CHANGE_MEMEBER_REDUCER_DATA = "REQUEST_TO_CHANGE_MEMEBER_REDUCER_DATA";

export const REQUEST_TO_GET_HOUSEHOLD_VILLAGE_WISE = "REQUEST_TO_GET_HOUSEHOLD_VILLAGE_WISE";
export const SUCCESS_TO_GET_HOUSEHOLD_VILLAGE_WISE = "SUCCESS_TO_GET_HOUSEHOLD_VILLAGE_WISE";
export const ERROR_TO_GET_HOUSEHOLD_VILLAGE_WISE = "ERROR_TO_GET_HOUSEHOLD_VILLAGE_WISE";

export const REQUEST_TO_ADD_HOUSEHOLD_SURVEY_DATA = "REQUEST_TO_ADD_HOUSEHOLD_SURVEY_DATA";
export const SUCCESS_TO_ADD_HOUSEHOLD_SURVEY_DATA = "SUCCESS_TO_ADD_HOUSEHOLD_SURVEY_DATA";
export const FAILED_TO_ADD_HOUSEHOLD_SURVEY_DATA = "FAILED_TO_ADD_HOUSEHOLD_SURVEY_DATA";

export const REQUEST_TO_GET_HOUSEHOLD_SURVEY_DATA = "REQUEST_TO_GET_HOUSEHOLD_SURVEY_DATA";
export const SUCCESS_TO_GET_HOUSEHOLD_SURVEY_DATA = "SUCCESS_TO_GET_HOUSEHOLD_SURVEY_DATA";
export const FAILED_TO_GET_HOUSEHOLD_SURVEY_DATA = "FAILED_TO_GET_HOUSEHOLD_SURVEY_DATA";

export const REQUEST_TO_ADD_VILLAGE_SURVEY_DATA = "REQUEST_TO_ADD_VILLAGE_SURVEY_DATA";
export const SUCCESS_TO_ADD_VILLAGE_SURVEY_DATA = "SUCCESS_TO_ADD_VILLAGE_SURVEY_DATA";
export const FAILED_TO_ADD_VILLAGE_SURVEY_DATA = "FAILED_TO_ADD_VILLAGE_SURVEY_DATA";

export const REQUEST_TO_GET_VILLAGE_SURVEY_DATA = "REQUEST_TO_GET_VILLAGE_SURVEY_DATA";
export const SUCCESS_TO_GET_VILLAGE_SURVEY_DATA = "SUCCESS_TO_GET_VILLAGE_SURVEY_DATA";
export const FAILED_TO_GET_VILLAGE_SURVEY_DATA = "FAILED_TO_GET_VILLAGE_SURVEY_DATA";

export const RESET_REDUCER_STATE = "RESET_REDUCER_STATE";

// route json
export const sidebar_items = [
    {
        menuId: 1,
        menuName: "Dashboard",
        menuNavItemId: "parent_menu_item_1",
        menuId: "dashboard_unique_id",
        aTagMenuId: "dashboard_unique_id_a",
        menuIcon: "mdi mdi-home menu-icon",
        menuRoute: "/dashboard",
        submenuItems: []
    },
    {
        menuId: 2,
        menuName: "Manage Population",
        menuNavItemId: "parent_menu_item_2",
        menuId: "manage_population_unique_id",
        aTagMenuId: "manage_population_unique_id_a",
        menuIcon: "mdi mdi-account-multiple menu-icon",
        submenuItems: [
            {
                submenuId: 1,
                submenuName: "Panchayets",
                submenuRoute: "/master/panchayet-list",
                submenuNavItemId: "child_menu_item_1",
            },
            {
                submenuId: 2,
                submenuName: "Villages",
                submenuRoute: "/master/village-list",
                submenuNavItemId: "child_menu_item_2",
            },
            {
                submenuId: 3,
                submenuName: "Households",
                submenuRoute: "/master/household-list",
                submenuNavItemId: "child_menu_item_3",
            },
            {
                submenuId: 5,
                submenuName: "Add Member",
                submenuRoute: "/family/add-member",
                submenuNavItemId: "child_menu_item_4",
            },
            {
                submenuId: 6,
                submenuName: "All Members",
                submenuRoute: "/family/members",
                submenuNavItemId: "child_menu_item_5",
            },
            {
                submenuId: 7,
                submenuName: "Add User",
                submenuRoute: "/user/add",
                submenuNavItemId: "child_menu_item_6",
            },
            {
                submenuId: 8,
                submenuName: "Login Users",
                submenuRoute: "/user/all",
                submenuNavItemId: "child_menu_item_7",
            }
        ]
    },
    {
        menuId: 3,
        menuName: "Medicine Inventory",
        menuNavItemId: "parent_menu_item_3",
        menuId: "medicine_inventory_unique_id",
        aTagMenuId: "medicine_inventory_unique_id_a",
        menuIcon: "mdi mdi-ambulance menu-icon",
        submenuItems: [
            {
                submenuId: 1,
                submenuName: "Categories",
                submenuRoute: "/medicine/categories",
                submenuNavItemId: "child_menu_item_8",
            },
            {
                submenuId: 2,
                submenuName: "Add Categories",
                submenuRoute: "/medicine/category/add",
                submenuNavItemId: "child_menu_item_9",
            },
            {
                submenuId: 3,
                submenuName: "Medicines",
                submenuRoute: "/medicines",
                submenuNavItemId: "child_menu_item_10",
            },
            {
                submenuId: 4,
                submenuName: "Add Medicines",
                submenuRoute: "/medicine/add",
                submenuNavItemId: "child_menu_item_11",
            },
            {
                submenuId: 5,
                submenuName: "Add Stock",
                submenuRoute: "/medicine-stock/add",
                submenuNavItemId: "child_menu_item_12",
            },
            {
                submenuId: 6,
                submenuName: "Stock History",
                submenuRoute: "/medicine-stock/history",
                submenuNavItemId: "child_menu_item_13",
            }
        ]
    }
]