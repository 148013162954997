import React, { Fragment, useEffect, useState } from "react";
import { commonApiService, getHttpHeaders } from "../../utils/Helpers";
import { GET, PRESCRIPTION_DETAILS_ROUTE } from "../../utils/Constants";
import { useParams } from "react-router-dom";
import Loader from "../../sharedComponent/Loader";
import MalePrescription from "./MalePrescription";
import FemalePrescription from "./FemalePrescription";
import ChildPrescription from "./ChildPrescription";

let apiCalled = 0;

const PrintPrescription = () => {
    const params = useParams();
    // console.log(params.id);

    const [patientType, setPatientType] = useState(null);
    const [loading, setLoading] = useState(true);
    const [prescriptionData, setPrescriptionData] = useState(null);

    useEffect(() => {
        _getPrescriptionData();
    }, []);
    useEffect(() => {
        if (!!prescriptionData && apiCalled === 1) {
            setTimeout(() => {
                window.print();
            }, 1000);
        }
    }, [prescriptionData]);
    const _getPrescriptionData = async () => {
        const header = getHttpHeaders("withAuth");
        const apiResponse = await commonApiService(GET, `${PRESCRIPTION_DETAILS_ROUTE}/${params.id}`, header);
        // console.log(apiResponse.data);
        setLoading(false);
        setPatientType(apiResponse.data?.patientType);
        setPrescriptionData(apiResponse.data);
        apiCalled++;
    }
    return (
        <div
            style={{
                width: '100%',
                overflow: 'auto'
            }}
        >
            {loading ? (
                <Loader />
            ) : (
                patientType === 'Male' ? (
                    <MalePrescription {...prescriptionData} />
                ) : (
                    patientType === 'Female' ? (
                        <FemalePrescription {...prescriptionData} />
                    ) : (
                        <ChildPrescription {...prescriptionData} />
                    )
                )
            )}
        </div>
    )
}

export default PrintPrescription;