import toast from "react-hot-toast";
import { DELETE, ERROR, ERROR_API_404_ALERT, ERROR_API_500_ALERT, GET, JWT_AUTH_TOKEN, POST, PUT, SUCCESS } from "./Constants";
import axios from "axios";

const TOKEN_KEY = 'jwt';

export const login = () => {
    localStorage.setItem(TOKEN_KEY, 'TestLogin');
}

export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
}

export const isLogin = () => {
    if (localStorage.getItem(TOKEN_KEY)) {
        return true;
    }
    return false;
}

export const LoadExternalScript = () => {
    const externalScript = document.createElement("script");
    // externalScript.onerror = loadError;
    externalScript.id = "external";
    externalScript.async = true;
    externalScript.type = "text/javascript";
    externalScript.setAttribute("crossorigin", "anonymous");
    document.body.appendChild(externalScript);
    //externalScript.src = '../assets/js/misc.js';
};
export const LoadDataTableJs = () => {
    const externalScript = document.createElement("script");
    // externalScript.onerror = loadError;
    externalScript.id = "data-table";
    externalScript.async = true;
    externalScript.type = "text/javascript";
    externalScript.setAttribute("crossorigin", "anonymous");
    document.body.appendChild(externalScript);
    externalScript.src = '../assets/vendors/data-table.js';
}
export const displayAlert = (alertType = SUCCESS, message) => {
    switch (alertType) {
        case ERROR:
            toast.error(message);
            break;

        case SUCCESS:
            toast.success(message);
            break;
    }
}
export function ValidateEmail(input) {
    var validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!validRegex.test(input)) return false;
    return true;
}
export function hasToken() {
    let authToken = localStorage.getItem(JWT_AUTH_TOKEN);
    if (!!!authToken) {
        return false;
    }
    return true;
}
export function getHttpHeaders(type) {
    const httpHeaders = {
        "Accept": "application/json"
    };
    const authToken = localStorage.getItem(JWT_AUTH_TOKEN);
    if (type === "withAuth") {
        httpHeaders.Authorization = authToken;
    }
    return httpHeaders;
}
export function commonApiService(method = GET, url, headers, body = {}) {
    let API_URL = `${process.env.REACT_APP_API_ENDPOINT}${url}`;
    switch (method) {
        case POST:
            return axios.post(API_URL, body, {
                headers: headers
            });
        case PUT:
            return axios.put(API_URL, body, {
                headers: headers
            });
        case DELETE:
            return axios.delete(API_URL, body, {
                headers: headers
            });
        default:
            return axios.get(API_URL, {
                headers: headers
            });
    }
}
export function handleErrorBlock(error) {
    if (error?.response?.status === 404) {
        displayAlert(ERROR, ERROR_API_404_ALERT);
    } else if (error?.response?.status === 401) {
        displayAlert(ERROR, error?.response?.data?.message);
        localStorage.clear();
        window.location.href = '/login';
    } else if (error?.response?.status === 500) {
        displayAlert(ERROR, !!error?.response?.data?.message ? error?.response?.data?.message : ERROR_API_500_ALERT);
    } else if (error?.response?.status === 405) {
        displayAlert(ERROR, error?.response?.data?.message);
    } else if (error?.response?.status === 400) {
        displayAlert(ERROR, error?.response?.data?.message);
    }
}
export function FindInArray(main_array, search_element) {
    if (Array.isArray(main_array)) {
        const result = main_array.filter(item => item?.id === search_element);
        return result[0]?.title;
    }
    return null;
}
export function checkempty(value) {
    if (value == null ||
        value == undefined ||
        value.length == 0) {
        return false;
    } else {
        return true;
    }
}
export function checkPhoneNumber(inputtxt) {
    var phoneno = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
        return true;
    } else {
        return false;
    }
}
export function removeTags(str) {
    if ((str === null) || (str === ''))
        return false;
    else
        str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/ig, '');
}
export function GetFullName(firstName, middleName, lastName) {
    if (!!middleName) {
        return `${firstName} ${middleName} ${lastName}`;
    }
    return `${firstName} ${lastName}`;
}
