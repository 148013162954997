import { FAILED_GET_DASHBOARD_DATA, REQUEST_GET_DASHBOARD_DATA, SUCCESS_GET_DASHBOARD_DATA } from "../../utils/Constants";

const initialState = {
    status: null,
    dashboardDataIsRequested: false,
    dashboardData: null,
    dashboardDataError: null
}

const DashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_GET_DASHBOARD_DATA:
            return {
                ...state,
                status: action.type,
                dashboardDataIsRequested: true
            }
        case SUCCESS_GET_DASHBOARD_DATA:
            return {
                ...state,
                status: action.type,
                dashboardDataIsRequested: false,
                dashboardData: action.response
            }
        case FAILED_GET_DASHBOARD_DATA:
            return {
                ...state,
                status: action.type,
                dashboardDataIsRequested: false,
                dashboardDataError: action.error
            }
        default:
            return state;
    }
}

export default DashboardReducer;