import { FAILED_TO_ADD_HOUSEHOLD_SURVEY_DATA, FAILED_TO_ADD_VILLAGE_SURVEY_DATA, FAILED_TO_GET_HOUSEHOLD_SURVEY_DATA, FAILED_TO_GET_VILLAGE_SURVEY_DATA, REQUEST_MASTER_LIST, REQUEST_TO_ADD_HOUSEHOLD_SURVEY_DATA, REQUEST_TO_ADD_VILLAGE_SURVEY_DATA, REQUEST_TO_GET_HOUSEHOLD_SURVEY_DATA, REQUEST_TO_GET_VILLAGE_SURVEY_DATA, SUCCESS_MASTER_LIST, SUCCESS_TO_ADD_HOUSEHOLD_SURVEY_DATA, SUCCESS_TO_ADD_VILLAGE_SURVEY_DATA, SUCCESS_TO_GET_HOUSEHOLD_SURVEY_DATA, SUCCESS_TO_GET_VILLAGE_SURVEY_DATA } from "../../utils/Constants";

const initialState = {
    status: "",
    isActivityLoader: false,
    panchayet: null,
    village: null,
    household: null,
    householdSurveyDataActivityLoader: false,
    householdSurveyDataAddResponse: null,
    householdSurveyData: [],
    householdSurveyDataGetActivityLoader: false,
    villageSurveyDataActivityLoader: false,
    villageSurveyDataAddResponse: null,
    villageSurveyData: [],
    villageSurveyDataGetActivityLoader: false,
    error: null
}

const MasterReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_MASTER_LIST:
            return {
                ...state,
                status: action.type,
                isActivityLoader: true
            }
        case SUCCESS_MASTER_LIST:
            if (action.payload === "panchayet") {
                return {
                    ...state,
                    status: action.type,
                    isActivityLoader: false,
                    panchayet: action.response
                }
            } else if (action.payload === "village") {
                return {
                    ...state,
                    status: action.type,
                    isActivityLoader: false,
                    village: action.response
                }
            } else if (action.payload === "household") {
                return {
                    ...state,
                    status: action.type,
                    isActivityLoader: false,
                    household: action.response
                }
            }
            break;
        case REQUEST_TO_ADD_HOUSEHOLD_SURVEY_DATA:
            return {
                ...state,
                status: action.type,
                householdSurveyDataActivityLoader: true
            }
        case SUCCESS_TO_ADD_HOUSEHOLD_SURVEY_DATA:
            return {
                ...state,
                status: action.type,
                householdSurveyDataAddResponse: action?.response,
                householdSurveyDataActivityLoader: false
            }
        case FAILED_TO_ADD_HOUSEHOLD_SURVEY_DATA:
            return {
                ...state,
                status: action.type,
                householdSurveyDataActivityLoader: false,
                error: action?.error
            }
        case REQUEST_TO_GET_HOUSEHOLD_SURVEY_DATA:
            return {
                ...state,
                householdSurveyDataGetActivityLoader: true,
                status: action.type
            }
        case SUCCESS_TO_GET_HOUSEHOLD_SURVEY_DATA:
            return {
                ...state,
                status: action.type,
                householdSurveyDataGetActivityLoader: false,
                householdSurveyData: action?.response
            }
        case FAILED_TO_GET_HOUSEHOLD_SURVEY_DATA:
            return {
                ...state,
                status: action.type,
                householdSurveyDataGetActivityLoader: false,
                error: action?.error
            }
        case REQUEST_TO_ADD_VILLAGE_SURVEY_DATA:
            return {
                ...state,
                status: action.type,
                villageSurveyDataActivityLoader: true
            }
        case SUCCESS_TO_ADD_VILLAGE_SURVEY_DATA:
            return {
                ...state,
                status: action.type,
                villageSurveyDataAddResponse: action?.response,
                villageSurveyDataActivityLoader: false
            }
        case FAILED_TO_ADD_VILLAGE_SURVEY_DATA:
            return {
                ...state,
                status: action.type,
                villageSurveyDataActivityLoader: false,
                error: action?.error
            }
        case REQUEST_TO_GET_VILLAGE_SURVEY_DATA:
            return {
                ...state,
                villageSurveyDataGetActivityLoader: true,
                status: action.type
            }
        case SUCCESS_TO_GET_VILLAGE_SURVEY_DATA:
            return {
                ...state,
                status: action.type,
                villageSurveyDataGetActivityLoader: false,
                villageSurveyData: action?.response
            }
        case FAILED_TO_GET_VILLAGE_SURVEY_DATA:
            return {
                ...state,
                status: action.type,
                villageSurveyDataGetActivityLoader: false,
                error: action?.error
            }
        default:
            return state;
    }
}

export default MasterReducer;