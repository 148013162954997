import { configureStore } from '@reduxjs/toolkit';
import RootReducer from '../reducers/RootReducer';
import createSagaMiddleware from 'redux-saga';
import RootSaga from '../../saga/RootSaga';

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];

const store = configureStore({
    reducer: RootReducer,
    middleware: middleware
});

sagaMiddleware.run(RootSaga);

export default store;