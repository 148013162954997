import { combineReducers } from 'redux';
import AuthenticationReducer from './AuthenticationReducer';
import MasterReducer from './MastersReducer';
import FamilyMemberReducer from './FamilyMemberReducer';
import DashboardReducer from './DashboardReducer';

const allReducers = combineReducers({
    AuthenticationReducer: AuthenticationReducer,
    MasterReducer: MasterReducer,
    FamilyMemberReducer: FamilyMemberReducer,
    DashboardReducer: DashboardReducer
})

const RootReducer = (state, action) => {
    return allReducers(state, action);
}

export default RootReducer;