import { FAILED_LOGIN_USER_DETAILS, FAILED_USER_LOGIN, REQUEST_LOGIN_USER_DETAILS, REQUEST_USER_LOGIN, SUCCESS_LOGIN_USER_DETAILS, SUCCESS_USER_LOGIN } from "../../utils/Constants";
import { hasToken } from "../../utils/Helpers";

let initialState = {
    status: "",
    userLoginResponse: null,
    logUserAllDetails: null,
    loginError: null,
    loginUserDetailsError: null,
    isActivityLoader: false,
    isLoggedin: hasToken()
}

const AuthenticationReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_USER_LOGIN:
            return {
                ...state,
                isActivityLoader: true,
                status: action.type
            }
        case SUCCESS_USER_LOGIN:
            return {
                ...state,
                isActivityLoader: false,
                status: action.type,
                userLoginResponse: action.response,
                isLoggedin: true
            }
        case FAILED_USER_LOGIN:
            return {
                ...state,
                isActivityLoader: false,
                status: action.type,
                loginError: action.error,
                isLoggedin: false
            }
        case REQUEST_LOGIN_USER_DETAILS:
            return {
                ...state,
                status: action.type
            }
        case SUCCESS_LOGIN_USER_DETAILS:
            return {
                ...state,
                status: action.type,
                logUserAllDetails: action.response
            }
        case FAILED_LOGIN_USER_DETAILS:
            return {
                ...state,
                status: action.type,
                loginUserDetailsError: action.error
            }
        default:
            return state;
    }
}

export default AuthenticationReducer;