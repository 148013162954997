import { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from './Routes';
import Loader from '../sharedComponent/Loader';
import CommonNavbar from '../sharedComponent/Navbar';
import CommonSideBar from '../sharedComponent/Sidebar';
import Footer from '../sharedComponent/Footer';

const ProtectedRoutes = (props) => (
    <Switch>
        <Suspense
            fallback={<Loader />}
        >
            <div className="container-scroller">
                {props.urlParameter !== "mobile" && (
                    <CommonNavbar />
                )}
                <div className="container-fluid page-body-wrapper">
                    {props.urlParameter !== "mobile" && (
                        <CommonSideBar />
                    )}
                    {routes.map(({ component: Component, path, exact }) => (
                        <Route
                            path={`/${path}`}
                            key={path}
                            exact={exact}
                        >
                            <Component />
                        </Route>
                    ))}
                </div>
                {props.urlParameter !== "mobile" && (
                    <Footer />
                )}
            </div>
        </Suspense>
    </Switch>
);

export default ProtectedRoutes;