import React from 'react';
import './App.css';
import RootNavigation from './navigation/RootNavigation';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import store from './redux/store/Store';

function App() {
	return (
		<Provider store={store}>
			<RootNavigation />
			<Toaster />
		</Provider>
	);
}

export default App;
