import React from "react";
import FooterImageLogo from "../assets/images/Frame 11.png";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container-fluid d-flex justify-content-between">
                <span className="text-muted d-block text-center text-sm-start d-sm-inline-block">Powered by: Kajla Janakalyan Samity<img class="ft-image" src={FooterImageLogo} /></span>
                {/* <span className="float-none float-sm-end mt-1 mt-sm-0 text-end"> Free <a href="https://www.bootstrapdash.com/bootstrap-admin-template/" target="_blank">Bootstrap admin template</a> from Bootstrapdash.com</span> */}
            </div>
        </footer>
    )
}

export default Footer;