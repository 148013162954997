import { REQUEST_LOGIN_USER_DETAILS, REQUEST_USER_LOGIN } from "../../utils/Constants";

export const userLogniAction = (payload ) => {
    return {
        type: REQUEST_USER_LOGIN,
        payload 
    }
}
export const getLoginUserDetails = () => {
    return {
        type: REQUEST_LOGIN_USER_DETAILS
    }
}