import { call, put, takeLatest } from 'redux-saga/effects';
import { commonApiService, displayAlert, getHttpHeaders, handleErrorBlock } from '../utils/Helpers';
import { ERROR, FAILED_MASTER_LIST, FAILED_TO_ADD_HOUSEHOLD_SURVEY_DATA, FAILED_TO_ADD_VILLAGE_SURVEY_DATA, FAILED_TO_GET_HOUSEHOLD_SURVEY_DATA, FAILED_TO_GET_VILLAGE_SURVEY_DATA, GET, GET_HOUSEHOLD_SURVEY_DATA, GET_VILLAGE_SURVEY_DATA, HOUSEHOLD_SURVEY_DATA_ADD, MASTER_ROUTE, POST, REQUEST_MASTER_LIST, REQUEST_TO_ADD_HOUSEHOLD_SURVEY_DATA, REQUEST_TO_ADD_VILLAGE_SURVEY_DATA, REQUEST_TO_GET_HOUSEHOLD_SURVEY_DATA, REQUEST_TO_GET_VILLAGE_SURVEY_DATA, SUCCESS, SUCCESS_MASTER_LIST, SUCCESS_TO_ADD_HOUSEHOLD_SURVEY_DATA, SUCCESS_TO_ADD_VILLAGE_SURVEY_DATA, SUCCESS_TO_GET_HOUSEHOLD_SURVEY_DATA, SUCCESS_TO_GET_VILLAGE_SURVEY_DATA, VILLAGE_SURVEY_DATA_ADD } from '../utils/Constants';

export function* GetAllMasterData(action) {
    try {
        const headers = getHttpHeaders('withAuth');
        const apiResponse = yield call(commonApiService, GET, `${MASTER_ROUTE}${action.payload}`, headers);
        // console.log(apiResponse?.status);
        if (apiResponse?.status === 200) {
            if (apiResponse?.data?.status === false) {
                displayAlert(ERROR, apiResponse?.data?.message);
            } else {
                // console.log(apiResponse?.data?.message);
                displayAlert(SUCCESS, apiResponse?.data?.message);
                yield put({
                    type: SUCCESS_MASTER_LIST,
                    response: apiResponse?.data,
                    payload: action.payload
                });
            }
        }
    } catch (error) {
        handleErrorBlock(error);
        yield put({
            type: FAILED_MASTER_LIST,
            error: error
        });
    }
}
export function* watchGetAllMasterData() {
    yield takeLatest(REQUEST_MASTER_LIST, GetAllMasterData);
}
export function* RequestToAddSurveyDataForHousehold(action) {
    try {
        const headers = getHttpHeaders('withAuth');
        const apiResponse = yield call(commonApiService, POST, `${HOUSEHOLD_SURVEY_DATA_ADD}`, headers, action?.payload);
        // console.log(apiResponse?.status);
        if (apiResponse?.status === 200) {
            if (apiResponse?.data?.status === false) {
                displayAlert(ERROR, apiResponse?.data?.message);
                yield put({
                    type: FAILED_TO_ADD_HOUSEHOLD_SURVEY_DATA,
                    error: {
                        message: !!apiResponse?.data?.message ? apiResponse?.data?.message : "Something went wrong"
                    }
                });
            } else {
                // console.log(apiResponse?.data?.message);
                displayAlert(SUCCESS, apiResponse?.data?.message);
                yield put({
                    type: SUCCESS_TO_ADD_HOUSEHOLD_SURVEY_DATA,
                    response: apiResponse?.data
                });
            }
        }
    } catch (error) {
        yield put({
            type: FAILED_TO_ADD_HOUSEHOLD_SURVEY_DATA,
            error: error
        });
    }
}
export function* WatchRequestToAddSurveyDataForHousehold() {
    yield takeLatest(REQUEST_TO_ADD_HOUSEHOLD_SURVEY_DATA, RequestToAddSurveyDataForHousehold);
}
export function* GetAllHouseholdSurveyData(action) {
    try {
        const headers = getHttpHeaders('withAuth');
        const apiResponse = yield call(commonApiService, GET, `${GET_HOUSEHOLD_SURVEY_DATA}/${action?.payload}`, headers);
        // console.log(apiResponse?.status);
        if (apiResponse?.status === 200) {
            if (apiResponse?.data?.status === false) {
                displayAlert(ERROR, apiResponse?.data?.message);
                yield put({
                    type: FAILED_TO_GET_HOUSEHOLD_SURVEY_DATA,
                    error: {
                        message: !!apiResponse?.data?.message ? apiResponse?.data?.message : "Something went wrong"
                    }
                });
            } else {
                // console.log(apiResponse?.data?.message);
                displayAlert(SUCCESS, apiResponse?.data?.message);
                yield put({
                    type: SUCCESS_TO_GET_HOUSEHOLD_SURVEY_DATA,
                    response: apiResponse?.data
                });
            }
        }
    } catch (error) {
        yield put({
            type: FAILED_TO_GET_HOUSEHOLD_SURVEY_DATA,
            error: error
        });
    }
}
export function* WatchGetAllHouseholdSurveyData() {
    yield takeLatest(REQUEST_TO_GET_HOUSEHOLD_SURVEY_DATA, GetAllHouseholdSurveyData);
}
export function* RequestToAddSurveyDataForVillage(action) {
    try {
        const headers = getHttpHeaders('withAuth');
        const apiResponse = yield call(commonApiService, POST, `${VILLAGE_SURVEY_DATA_ADD}`, headers, action?.payload);
        // console.log(apiResponse?.status);
        if (apiResponse?.status === 200) {
            if (apiResponse?.data?.status === false) {
                displayAlert(ERROR, apiResponse?.data?.message);
                yield put({
                    type: FAILED_TO_ADD_VILLAGE_SURVEY_DATA,
                    error: {
                        message: !!apiResponse?.data?.message ? apiResponse?.data?.message : "Something went wrong"
                    }
                });
            } else {
                // console.log(apiResponse?.data?.message);
                displayAlert(SUCCESS, apiResponse?.data?.message);
                yield put({
                    type: SUCCESS_TO_ADD_VILLAGE_SURVEY_DATA,
                    response: apiResponse?.data
                });
            }
        }
    } catch (error) {
        yield put({
            type: FAILED_TO_ADD_VILLAGE_SURVEY_DATA,
            error: error
        });
    }
}
export function* WatchRequestToAddSurveyDataForVillage() {
    yield takeLatest(REQUEST_TO_ADD_VILLAGE_SURVEY_DATA, RequestToAddSurveyDataForVillage);
}
export function* GetAllVillageSurveyData(action) {
    try {
        const headers = getHttpHeaders('withAuth');
        const apiResponse = yield call(commonApiService, GET, `${GET_VILLAGE_SURVEY_DATA}/${action?.payload}`, headers);
        // console.log(apiResponse?.status);
        if (apiResponse?.status === 200) {
            if (apiResponse?.data?.status === false) {
                displayAlert(ERROR, apiResponse?.data?.message);
                yield put({
                    type: FAILED_TO_GET_VILLAGE_SURVEY_DATA,
                    error: {
                        message: !!apiResponse?.data?.message ? apiResponse?.data?.message : "Something went wrong"
                    }
                });
            } else {
                // console.log(apiResponse?.data?.message);
                displayAlert(SUCCESS, apiResponse?.data?.message);
                yield put({
                    type: SUCCESS_TO_GET_VILLAGE_SURVEY_DATA,
                    response: apiResponse?.data
                });
            }
        }
    } catch (error) {
        yield put({
            type: FAILED_TO_GET_VILLAGE_SURVEY_DATA,
            error: error
        });
    }
}
export function* WatchGetAllVillageSurveyData() {
    yield takeLatest(REQUEST_TO_GET_VILLAGE_SURVEY_DATA, GetAllVillageSurveyData);
}