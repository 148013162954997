import { call, put, takeLatest } from 'redux-saga/effects';
import { commonApiService, displayAlert, getHttpHeaders, handleErrorBlock } from '../utils/Helpers';
import { GET, DASHBOARD_DATA, ERROR, FAILED_GET_DASHBOARD_DATA, REQUEST_GET_DASHBOARD_DATA, SUCCESS, SUCCESS_GET_DASHBOARD_DATA } from '../utils/Constants';

export function* GetAllDashboardDataSaga() {
    try {
        const headers = getHttpHeaders('withAuth');
        const apiResponse = yield call(commonApiService, GET, `${DASHBOARD_DATA}`, headers);
        console.log(apiResponse?.status);
        if (apiResponse?.status === 200) {
            if (apiResponse?.data?.status === false) {
                displayAlert(ERROR, apiResponse?.data?.message);
            } else {
                console.log(apiResponse?.data?.message);
                displayAlert(SUCCESS, apiResponse?.data?.message);
                yield put({
                    type: SUCCESS_GET_DASHBOARD_DATA,
                    response: apiResponse?.data
                });
            }
        }
    } catch (error) {
        handleErrorBlock(error);
        yield put({
            type: FAILED_GET_DASHBOARD_DATA,
            error: error
        });
    }
}
export function* watchGetAllDashboardDataSaga() {
    yield takeLatest(REQUEST_GET_DASHBOARD_DATA, GetAllDashboardDataSaga);
}