import { all } from 'redux-saga/effects';
import { watchGetLoginUserData, watchPerformLogin } from './AuthenticationSaga';
import { WatchGetAllHouseholdSurveyData, WatchGetAllVillageSurveyData, WatchRequestToAddSurveyDataForHousehold, WatchRequestToAddSurveyDataForVillage, watchGetAllMasterData } from './MasterSaga';
import { WatchGetHouseHoldsDropdownData, watchGetFamilyMembersData, watchPerformAddEditMemberData, watchPerformUploadMemberPhoto } from './FamilyMemberSaga';
import { watchGetAllDashboardDataSaga } from './DashboardSaga';

function* RootSaga() {
    yield all([
        watchPerformLogin(),
        watchGetLoginUserData(),
        watchGetAllMasterData(),
        watchGetFamilyMembersData(),
        watchPerformAddEditMemberData(),
        watchGetAllDashboardDataSaga(),
        watchPerformUploadMemberPhoto(),
        WatchGetHouseHoldsDropdownData(),
        WatchRequestToAddSurveyDataForHousehold(),
        WatchGetAllHouseholdSurveyData(),
        WatchRequestToAddSurveyDataForVillage(),
        WatchGetAllVillageSurveyData()
    ]);
}

export default RootSaga;