import React, { Fragment } from "react";
import Frame10 from "../../assets/images/Frame 10.png";
import Frame11 from "../../assets/images/Frame 11.png";
import moment from "moment";
import { GetFullName } from "../../utils/Helpers";

const ChildPrescription = (props) => {
    // console.log(props);
    return (
        <Fragment>
            <div
                style={{
                    width: '710px',
                    margin: 'auto',
                    background: '#eef3f5'
                }}
            >
                <table
                    cellpadding={0}
                    cellspacing={0}
                    style={{
                        width: '100%'
                    }}
                >
                    <tbody>
                        <tr
                            style={{
                                height: '40px'
                            }}
                        >
                            <td
                                style={{
                                    background: '#023570'
                                }}
                                colspan={3}
                            >

                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    width: '9%',
                                    verticalAlign: 'text-bottom',
                                    padding: '10px',
                                    paddingRight: '0'
                                }}
                            >
                                <img
                                    src={Frame10}
                                    style={{
                                        width: '70px'
                                    }}
                                />

                            </td>
                            <td
                                style={{
                                    padding: '7.5px'
                                }}
                            >
                                <table
                                    cellpadding={0}
                                    cellspacing={0}
                                    style={{
                                        width: '100%',
                                        fontSize: '13px'
                                    }}
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{
                                                    width: '25%',
                                                    padding: '2.5px 5px'
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        width: '100%',
                                                        display: 'inline-block',
                                                        borderBottom: '1px dotted',
                                                        height: '24px'
                                                    }}
                                                >
                                                    <i
                                                        style={{
                                                            background: '#eef3f5',
                                                            height: '110%',
                                                            display: 'inline-block',
                                                            lineHeight: '24px',
                                                            fontStyle: 'normal',
                                                            paddingRight: '6px'
                                                        }}
                                                    >Date:</i>{moment(props.createdDateTime).format('DD/MM/YYYY')}
                                                </span>
                                            </td>
                                            <td></td>
                                            <td
                                                colspan={2}
                                                style={{
                                                    padding: '2.5px 5px',
                                                    width: '25%'
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        width: '100%',
                                                        display: 'inline-block',
                                                        borderBottom: '1px dotted',
                                                        height: '24px'
                                                    }}
                                                >
                                                    <i
                                                        style={{
                                                            background: '#eef3f5',
                                                            height: '110%',
                                                            display: 'inline-block',
                                                            lineHeight: '24px',
                                                            fontStyle: 'normal',
                                                            paddingRight: '6px'
                                                        }}
                                                    >Patient
                                                        Code :</i>{props?.patient?.patientDetails?.patientCode}</span></td>
                                        </tr>
                                        <tr>
                                            <td
                                                colspan={2}
                                                style={{
                                                    padding: '2.5px 5px'
                                                }}
                                            >
                                                <span
                                                    style=
                                                    {{
                                                        width: '100%',
                                                        display: 'inline-block',
                                                        borderBottom: '1px dotted',
                                                        height: '24px'
                                                    }}
                                                >
                                                    <i
                                                        style={{
                                                            background: '#eef3f5',
                                                            height: '110%',
                                                            display: 'inline-block',
                                                            lineHeight: '24px',
                                                            fontStyle: 'normal',
                                                            paddingRight: '6px'
                                                        }}
                                                    >Village
                                                        :</i>
                                                    {props?.patient?.villageDetails?.villageName}
                                                </span>
                                            </td>
                                            <td
                                                colspan={2}
                                                style={{
                                                    padding: '2.5px 5px'
                                                }}
                                            >
                                                <span
                                                    style=
                                                    {{
                                                        width: '100%',
                                                        display: 'inline-block',
                                                        borderBottom: '1px dotted',
                                                        height: '24px'
                                                    }}
                                                >
                                                    <i
                                                        style={{
                                                            background: '#eef3f5',
                                                            height: '110%',
                                                            display: 'inline-block',
                                                            lineHeight: '24px',
                                                            fontStyle: 'normal',
                                                            paddingRight: '6px'
                                                        }}
                                                    >Household
                                                        No :</i>{props?.patient?.householdDetails?.householdNumber}</span></td>
                                        </tr>
                                        <tr>
                                            <td colspan={4}
                                                style={{
                                                    padding: '2.5px 5px'
                                                }}
                                            ><span
                                                style={{
                                                    width: '100%',
                                                    display: 'inline-block',
                                                    borderBottom: '1px dotted',
                                                    height: '24px'
                                                }}
                                            >
                                                    <i
                                                        style={{
                                                            background: '#eef3f5',
                                                            height: '110%',
                                                            display: 'inline-block',
                                                            lineHeight: '24px',
                                                            fontStyle: 'normal',
                                                            paddingRight: '6px'
                                                        }}
                                                    >Name
                                                        :</i>{GetFullName(props?.patient?.firstName, props?.patient?.middleName, props?.patient?.lastName)}</span></td>
                                        </tr>
                                        <tr>
                                            <td
                                                style={{
                                                    padding: '2.5px 5px'
                                                }}
                                            ><span
                                                style={{
                                                    width: '100%',
                                                    display: 'inline-block',
                                                    borderBottom: '1px dotted',
                                                    height: '24px'
                                                }}
                                            >
                                                    <i
                                                        style={{
                                                            background: '#eef3f5',
                                                            height: '110%',
                                                            display: 'inline-block',
                                                            lineHeight: '24px',
                                                            fontStyle: 'normal',
                                                            paddingRight: '6px'
                                                        }}
                                                    >DOB
                                                        :</i>{moment(props?.patient?.dateOfBirth).format('DD/MM/YYYY')}</span></td>
                                            <td
                                                style={{
                                                    padding: '2.5px 5px'
                                                }}
                                            ><span
                                                style={{
                                                    width: '100%',
                                                    display: 'inline-block',
                                                    height: '24px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                    <i
                                                        style={{
                                                            background: '#eef3f5',
                                                            height: '110%',
                                                            display: 'inline-block',
                                                            lineHeight: '24px',
                                                            fontStyle: 'normal',
                                                            paddingRight: '6px'
                                                        }}
                                                    >Gender
                                                        : {props?.patient?.gender === "Male" ? "M" : "F"}</i></span></td>

                                            <td colspan={2}
                                                style={{
                                                    padding: '2.5px 5px'
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        width: '100%',
                                                        display: 'inline-block',
                                                        borderBottom: '1px dotted',
                                                        height: '24px'
                                                    }}
                                                >
                                                    <i
                                                        style={{
                                                            background: '#eef3f5',
                                                            height: '110%',
                                                            display: 'inline-block',
                                                            lineHeight: '24px',
                                                            fontStyle: 'normal',
                                                            paddingRight: '6px'
                                                        }}
                                                    >Contact
                                                        No (If any) :</i>{props?.patient?.phoneNumber}</span></td>
                                        </tr>
                                        <tr>
                                            <td
                                                style={{
                                                    padding: '2.5px 5px',
                                                    width: '25%'
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        width: '100%',
                                                        display: 'inline-block',
                                                        borderBottom: '1px dotted',
                                                        height: '24px'
                                                    }}
                                                >
                                                    <i
                                                        style={{
                                                            background: '#eef3f5',
                                                            height: '110%',
                                                            display: 'inline-block',
                                                            lineHeight: '24px',
                                                            fontStyle: 'normal',
                                                            paddingRight: '6px'
                                                        }}
                                                    >Height
                                                        :</i>{JSON.parse(props?.patientData)?.height}<i
                                                            style={{
                                                                float: 'right',
                                                                background: '#eef3f5',
                                                                height: '110%',
                                                                display: 'inline-block',
                                                                lineHeight: '24px',
                                                                fontStyle: 'normal',
                                                                paddingLeft: '4px'
                                                            }}
                                                        >Cms.</i></span>
                                            </td>
                                            <td
                                                style={{
                                                    padding: '2.5px 5px',
                                                    width: '25%'
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        width: '100%',
                                                        display: 'inline-block',
                                                        borderBottom: '1px dotted',
                                                        height: '24px'
                                                    }}
                                                >
                                                    <i
                                                        style={{
                                                            background: '#eef3f5',
                                                            height: '110%',
                                                            display: 'inline-block',
                                                            lineHeight: '24px',
                                                            fontStyle: 'normal',
                                                            paddingRight: '6px'
                                                        }}
                                                    >Weight
                                                        :</i>{JSON.parse(props?.patientData)?.weight}<i
                                                            style={{
                                                                float: 'right',
                                                                background: '#eef3f5',
                                                                height: '110%',
                                                                display: 'inline-block',
                                                                lineHeight: '24px',
                                                                fontStyle: 'normal',
                                                                paddingLeft: '6px'
                                                            }}
                                                        >Kg.</i></span>
                                            </td>
                                            <td
                                                style={{
                                                    padding: '2.5px 5px',
                                                    width: '25%'
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        width: '100%',
                                                        display: 'inline-block',
                                                        borderBottom: '1px dotted',
                                                        height: '24px'
                                                    }}
                                                >
                                                    <i
                                                        style={{
                                                            background: '#eef3f5',
                                                            height: '110%',
                                                            display: 'inline-block',
                                                            lineHeight: '24px',
                                                            fontStyle: 'normal',
                                                            paddingRight: '6px'
                                                        }}
                                                    >BMI
                                                        :</i>{JSON.parse(props?.patientData)?.bmi}</span>
                                            </td>
                                            <td
                                                style={{
                                                    padding: '2.5px 5px',
                                                    width: '25%'
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        width: '100%',
                                                        display: 'inline-block',
                                                        borderBottom: '1px dotted',
                                                        height: '24px'
                                                    }}
                                                >
                                                    <i
                                                        style={{
                                                            background: '#eef3f5',
                                                            height: '110%',
                                                            display: 'inline-block',
                                                            lineHeight: '24px',
                                                            fontStyle: 'normal',
                                                            paddingRight: '6px'
                                                        }}
                                                    >Blood
                                                        Group
                                                        :</i>{JSON.parse(props?.patientData)?.bloodGroup}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td
                                style={{
                                    width: '16%',
                                    verticalAlign: 'bottom',
                                    padding: '10px',
                                    paddingLeft: '0'
                                }}
                            >
                                <img
                                    src={Frame11}
                                    style={{
                                        width: '130px'
                                    }}
                                />
                            </td>
                        </tr >
                        <tr>
                            <td
                                colspan={3}
                                style={{
                                    padding: '10px',
                                    paddingTop: '0'
                                }}
                            >
                                <table
                                    cellpadding={0}
                                    cellspacing={0}
                                    border={0}
                                    style={{
                                        width: '100%'
                                    }}
                                >
                                    <tbody>
                                        <tr>
                                            <td style={{
                                                fontSize: '15px',
                                                fontWeight: 'bold',
                                                textAlign: 'center',
                                                padding: '4px',
                                                width: '25%',
                                                border: '1px solid',
                                                borderRight: '0'
                                            }}
                                            >Diagnostic Test Require</td>
                                            <td style={{
                                                fontSize: '15px',
                                                fontWeight: 'bold',
                                                textAlign: 'center',
                                                padding: '4px',
                                                width: '75%',
                                                border: '1px solid'
                                            }}
                                            >Suspected Disease Name</td>
                                        </tr>
                                        <tr>
                                            <td
                                                style={{
                                                    border: '1px solid',
                                                    borderRight: '0',
                                                    borderTop: '0'
                                                }}>
                                                <div style={{
                                                    height: '250px',
                                                    borderBottom: '1px solid',
                                                    margin: '0',
                                                    padding: '8px'
                                                }}>
                                                    {props?.labTests?.map((labTestsItem, labTestsIndex) =>
                                                        <p style={{
                                                            fontSize: '13px',
                                                            margin: '2px 0'
                                                        }}
                                                            key={labTestsIndex}
                                                        >{labTestsIndex + 1}. {labTestsItem?.testName}</p>
                                                    )}
                                                </div>
                                                <div style={{
                                                    height: '530px',
                                                    margin: '0',
                                                    padding: '8px',

                                                }}
                                                >
                                                    <p style={{
                                                        fontSize: '13px',
                                                        margin: '2px 0'
                                                    }} />
                                                </div>
                                            </td>
                                            <td style={{
                                                verticalAlign: 'top',
                                                border: '1px solid',
                                                borderTop: '0'
                                            }}
                                            >
                                                <div style={{
                                                    height: '218px',
                                                    borderBottom: '1px solid',
                                                    margin: '0',
                                                    padding: '8px'
                                                }}>
                                                    {props?.diseases?.map((diseasesItem, diseasesIndex) =>
                                                        <p style={{
                                                            fontSize: '13px',
                                                            margin: '2px 0'
                                                        }}
                                                            key={diseasesIndex}
                                                        >{diseasesItem?.diseaseName}</p>
                                                    )}
                                                </div>
                                                <div style={{
                                                    fontSize: '15px',
                                                    fontWeight: 'bold',
                                                    textAlign: 'left',
                                                    padding: '4px',
                                                    width: '100%',
                                                    borderBottom: '1px solid',
                                                    height: '28px'
                                                }}
                                                >Medicine</div>
                                                <div style={{
                                                    height: '506px',
                                                    borderBottom: '1px solid',
                                                    margin: '0',
                                                    padding: '8px'
                                                }}>
                                                    {props?.medicines?.map((medicinesItem, medicinesIndex) =>
                                                        <p style={{
                                                            fontSize: '13px',
                                                            margin: '2px 0'
                                                        }}
                                                            key={medicinesIndex}
                                                        >
                                                            {medicinesIndex + 1}. {medicinesItem?.medicineDetails?.medicineName} - ({medicinesItem?.medicineCategory?.categoryName}) - {medicinesItem?.medicineSortForm?.sortCodes} - {medicinesItem?.numberOfDays} দিন.
                                                        </p>
                                                    )}
                                                </div>
                                                <div style={{
                                                    fontSize: '15px',
                                                    fontWeight: 'bold',
                                                    textAlign: 'left',
                                                    padding: '4px',
                                                    width: '100%',
                                                    borderBottom: '0',
                                                    height: '28px'
                                                }}
                                                >Doctor: <span style={{
                                                    fontSize: '13px',
                                                    fontWeight: 'normal'
                                                }}>{props?.doctorName}</span></div>
                                            </td>
                                        </tr>

                                    </tbody >
                                </table >
                            </td >
                        </tr >
                        <tr>
                            <td
                                colspan={3}
                                style={{
                                    border: '0',
                                    padding: '0 10px',
                                    paddingBottom: '10px'
                                }}
                            >
                                Note: Please ask to bring Immunization Card in case of Children
                            </td>
                        </tr>
                    </tbody >
                </table >
            </div >
        </Fragment >
    )
}

export default ChildPrescription;
